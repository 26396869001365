<template>
  <div class="mastickandCollect" ref="MASTICK">
    <div class="topChange">
      <div class="boxChange" :style="active == 1 ? 'font-size: 18px;color:#171717;border-bottom:2px solid #5473E8;' : ''" @click="() => { active = 1; }">
        <p>错题练习</p>
        <p>འདྲི་གཞི་ནོར་བ་སྦྱོང་བརྡར།</p>
      </div>
      <div class="boxChange" :style="active == 2 ? 'font-size: 18px;color:#171717;border-bottom:2px solid #5473E8;' : ''" @click="() => { active = 2; }">
        <p>收藏题目</p>
        <p>འདྲི་གཞི་ཉར་ཚགས།</p>
      </div>
    </div>
    <div class="mastickcenter">
      <div class="cionBg">
        <p class="numError">{{ errorNumber }}</p>
        <p class="textDetail">{{ active === 1 ? '错题数' : '收藏数' }}</p>
      </div>
    </div>
    <div class="allErroamsitc" @click="allErrorList">{{ active === 1 ? '全部错题' : '全部收藏' }}</div>
    <div class="mastickBootom" :style="'height:'+ bottomHeight + 'px'">
      <img v-if="errorNumber === 0" src="../assets/image/noting.png" alt="">
      <p v-if="errorNumber === 0">您{{ active === 1 ? '错误' : '收藏' }}的题目会自动记录到这里哟~</p>
      <div class="anythingNum" v-if="errorNumber > 0">
        <p class="left">{{ active === 1 ? '过往错题' : '我的收藏' }}</p>
        <p class="right">共错1题  <van-icon name="arrow" style="margin-left: 20px" /></p>
      </div>
      <div class="kong" v-if="errorNumber > 0"></div>
      <div class="subjectList" v-show="errorNumber > 0" :style="'height:'+ listHeight + 'px'">
        <div class="listContent" v-for="(item,index) in listError" :key="index" @click="lookerror(item)">
          <div class="iconWrap">
            <div class="icon">{{index + 1}}</div>
          </div>
          <p class="text">{{item.type}}</p>
          <p class="num">{{ item.num }}   <van-icon name="arrow" style="margin-left: 20px" /></p>
        </div>
      </div>
      <div class="clearError" v-if="errorNumber > 0" @click="clearError">清空我的{{ active === 1 ? '错题' : '收藏' }}</div>
    </div>
  </div>
</template>
<script>
import { errList, errorNum, errorNumAndList, errorClear } from '@/api/secondSelect'
export default {
  data() {
    return {
      active: 1,
      bottomHeight: 0,
      listHeight: 0,
      errorList: [],
      collectList: [],
      errorNumber: 0,
      car: 1,
      listError: []
    }
  },
  created() {
    this.car = this.$route.query.id
    this.getErrorList()
    this.geterrorNum()
    this.initData()
  },
  mounted() {
    this.bottomHeight = this.$refs.MASTICK.offsetHeight - 51 - 236 - 30
    this.listHeight = this.bottomHeight - 185 
    console.log(this.listHeight, this.bottomHeight)
  },
  methods: {
    /* 查询列表 */
    initData() {
      errorNumAndList({subject: this.car}).then(res => {
        this.listError = res.data
      })
    },
    /* 查询错题数量 */
    geterrorNum() {
      errorNum({subject: this.car}).then(res => {
        this.errorNumber = res.data.num || 0
      })
    },
    /* 查询所有错题 */
    getErrorList() {
      errList({subject: this.car}).then(res => {
        this.errorList = res.data
        
      })
    },
    clearError() {
      errorClear({subject: this.car}).then(res => {
        this.initData()
        this.geterrorNum()
        this.getErrorList()
      })
    },
    allErrorList() {
      localStorage.setItem('errortixing', JSON.stringify(this.errorList))
      this.$router.push({
        path:'/exercise',
        query: {
          id: 'error'
        }
      })
    },  
    lookerror(e) {
      localStorage.setItem('errortixing', JSON.stringify(this.errorList))
      this.$router.push({
        path:'/exercise',
        query: {
          id: 'error'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.mastickandCollect{
  width: 100%;
  height: 100%;
  background: #F0F2F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topChange{
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    .boxChange{
      width: 30%;
      height: 50px;
      border-bottom: 2px solid #fff;
      text-align: center;
      color: #6E7278;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
    }
  }
  .mastickcenter{
    width: 100%;
    height: 236px;
    background: url('../assets/image/masticskanderror.png');
    background-size: cover;
    .cionBg{
      width: 100%;
      height: 236px;
      background: url('../assets/image/iconerror.png') no-repeat;
      background-size: 100%;
      text-align: center;
      .numError{
        padding-top: 75px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 50px;
        color: #FFFFFF;
        font-style: normal;
        text-transform: none;
      }
      .textDetail{
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #FFFFFF;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .allErroamsitc{
    width: 284px;
    height: 44px;
    margin-top: -22px;
    background: linear-gradient( 90deg, #6493FF 0%, #477EFE 100%);
    border-radius: 22px 22px 22px 22px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 18px;
    line-height: 44px;
    color: #FFFFFF;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
  .mastickBootom{
    width: 100%;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    img{
      width: 190px;
      height: 190px;
      margin-top: 80px;
    }
    .anythingNum{
      width: 375px;
      min-height: 53px;
      background: #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left{
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 18px;
        color: #212121;
        margin-left: 10px;
      }
      .right{
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #212121;
        margin-right: 26px;
        display: flex;
        align-items: center;
      }
    }
    .kong{
      width: 100%;
      min-height: 10px;
      background: #F0F2F5;
    }
    .subjectList{
      width: 100%;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      .listContent{
        width: 342px;
        min-height: 70px;
        border-bottom: 1px solid #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .iconWrap{
          width: 24px;
          height: 24px;
          background: rgb(100, 147, 255, .5);
          border-radius: 50%;
          .icon{
            width: 20px;
            height: 20px;
            margin: 2px;
            background: rgb(100, 147, 255);
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            font-size: 12px;
            color: #FFFFFF;
          }
        }
      }
      .text{
        width: 290px;
        margin-left: 5px;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 超出部分隐藏 */
        text-overflow: ellipsis; /* 显示省略号 */
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 14px;
        color: #707070;
      }
      .num{
        font-family: PingFang SC, PingFang SC;
        font-weight: 800;
        font-size: 12px;
        color: #707070;
        display: flex;
        align-items: center;
      }
    }
  }
  .clearError{
    width: 350px;
    height: 44px;
    margin-top: 8px;
    background: linear-gradient( 90deg, #6493FF 0%, #477EFE 100%);
    border-radius: 22px 22px 22px 22px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 44px;
    letter-spacing: 2px;
    text-align: center;
    font-style: normal;
    text-transform: none;
  }
}
</style>
